button:disabled,
button[disabled]{
  cursor: not-allowed !important;
}

.employee_level_div{
    height: 10rem;
    border-radius: 3%;
}
.listingitemitext-font-weight-bold span{
    font-weight: bold !important;
}

.modal .modal-header .close span{
    display: block !important;
}
.modules_row_for_profile{
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ECF0F3;
}
.modules_row_for_profile td{
    border: none !important
}
.modules_row_for_profile td:first-child{
    width: 15%
}
.subscription{
    display: flex;
    justify-content: center;
    width: 50%;
    margin: auto;
}
.subscription .subscription_active_button{
    background-color: #5e72e4;
    color: white;
    padding: 1rem 4rem;
    margin: 0 1rem;
}
.subscription .subscription_inactive_button{
    background-color: white;
    color: #5e72e4;
    padding: 1rem 4rem;
    margin: 0 1rem;
}
.askBusinessChoice{
    width: 250px
}
.switch_business_div_module div{
    border: 1px solid #e3e3e3;
}
.switch_business_div_module div:nth-child(n+2){
    border-top: 0px solid #e3e3e3;
}













.aside-menu .menu-nav > .menu-item.menu-item-open > .menu-heading, .aside-menu .menu-nav > .menu-item.menu-item-open > .menu-link, .header .header-menu .menu-nav > .menu-item:hover:not(.menu-item-here):not(.menu-item-active) > .menu-link, .header .header-menu .menu-nav > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) > .menu-link{
    background-color: rgba(77, 89, 149, 0.06) !important;
}
.header .header-menu .menu-nav > .menu-item:hover:not(.menu-item-here):not(.menu-item-active) > .menu-link .menu-text, .header .header-menu .menu-nav > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) > .menu-link .menu-text {
    color: #3699FF !important;
}
.header-menu .menu-nav > .menu-item > .menu-link > .menu-arrow {
    display: none !important;
}
.header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item:hover:not(.menu-item-here):not(.menu-item-active) > .menu-link, .header-menu .menu-nav > .menu-item .menu-submenu > .menu-subnav > .menu-item.menu-item-hover:not(.menu-item-here):not(.menu-item-active) > .menu-link {
    background-color: #F3F6F9 !important;
}
.table_header .card-header, .table_header td, .table_header th{
    border-bottom: 0 !important;
}
.table_header td, .table_header th{
    text-align: center;
}
.table_header td{
    border-top: 1px solid #e0e0e0;
}
.table_sub_header{
    font-size: .925rem !important;
    font-weight: 500;
}

div.shadow-none .card-header, div.shadow-none .card-body {
    padding: 0 !important;
}

.checkbox.checkedcheckbox > span:after{
    border-color: white !important;
}
.checkbox.checkedcheckbox > span{
    background-color: #6993ff !important;
}

div.shadow-none .card-body .checkbox svg{
    display: none !important;
}
.editProfileModel > div{
    max-width: 1100px;
}
.dropzone > p{
    display: none;
}

.dropzone-default div{
    border-radius:0 !important;
    border-width: 0 !important;
}
.editProfile .wizard-step{
    flex: 1;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    color: #3F4254;
    padding: 2rem 2.5rem;
    background-color: #F3F6F9;
    border-radius: 5px 5px 0 0;
    cursor: pointer;
    height: 100px;
}
.editProfile .wizard-number{
    font-size: 1.3rem;
    font-weight: 600;
    flex: 0 0 2.75rem;
    height: 2.75rem;
    width: 2.75rem;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(54, 153, 255, 0.08);
    color: #3699FF;
    margin-right: 1rem;
    border-radius: 0.5rem;
}
.editProfile .wizard-title{
    font-size: 1.15rem;
    font-weight: 600;
}
.editProfile .wizard-desc{
    font-family: Poppins, Helvetica, "sans-serif";
    font-weight: 400;
    font-size: 1rem;
}
.editProfile .MuiStep-root.MuiStep-horizontal{
    flex: 1;
    padding: 0 4px 0 0;
}
.editProfile .MuiStep-root.MuiStep-horizontal:last-child{
    padding: 0;
}
.editProfile .MuiPaper-root{
    background-color: transparent !important;
}
.editProfile .MuiStep-root.MuiStep-horizontal:first-child:not(.MuiStep-completed) .wizard-number{
    color: #ffffff;
    background-color: #3699FF;
}
.editProfile .MuiStep-root.MuiStep-horizontal.MuiStep-completed + .MuiStep-root.MuiStep-horizontal:not(.MuiStep-completed) .wizard-number{
    color: #ffffff;
    background-color: #3699FF;
}

.editProfile .MuiStep-root.MuiStep-horizontal:first-child:not(.MuiStep-completed) .wizard-step{
    background-color: #fff;
}
.editProfile .MuiStep-root.MuiStep-horizontal.MuiStep-completed + .MuiStep-root.MuiStep-horizontal:not(.MuiStep-completed) .wizard-step{
    background-color: #fff;
}

.editProfile .MuiStep-root.MuiStep-horizontal:first-child:not(.MuiStep-completed) .wizard-title{
    color: #3699FF;
}
.editProfile .MuiStep-root.MuiStep-horizontal.MuiStep-completed + .MuiStep-root.MuiStep-horizontal:not(.MuiStep-completed) .wizard-title{
    color: #3699FF;
}

.wizard-step-active{
    background-color: #ffffff !important;
}
.subscriptionstepsheaders .wizard-step{
    width: 33.33%;
    float: left;
}

/* .updateProfileBody .nextButton{
    color: #FFFFFF !important;
    background-color: #3699FF !important;
    border-color: #3699FF !important;
} */
.updateProfileBody .footer{
    border-top: 1px solid #EBEDF3 !important
}
.updateProfileBody{
    background-color: white;
}

.updateProfileBody select{
    width: 100%;
    padding: 0.7rem;
    border-radius: 5px;
}
.updateProfileBody input, .updateProfileBody textarea, .updateProfileBody select{
    background-color: #ECF0F3;
    border-color: #ECF0F3;
}

.updateProfileBody .input-group-prepend{
    position: absolute;
    top: 12px
}
.updateProfileBody .input-group-prepend + input{
    padding-left: 36px;
}
.viewProfile .detail{
    flex: 1;
}
.viewProfile .detailsection{
    border-bottom: 1px solid #EBEDF3;
    padding-bottom: 2rem;
}
.viewProfile{
    background-color: white;
    padding: 2rem;
}
.viewProfile .image{
    width: 10rem;
    margin-right: 1rem;
}
.viewProfile .image > *{
    width: 10rem;
    height: 100%;
}
.modules_list_table .card-header{
    border-bottom: 1px solid #ECF0F3 !important;
    padding-bottom: 1.5rem !important;
}
.modules_list_table .table.table-bordered{
    border-bottom: 1px solid #ECF0F3 !important
}
.add_user_image{
    border-radius: 100px;
    width: 100px !important;
    height: 100px !important;
}
.add_user_card{
    height: 93%
}
.payment_method_logos{
    width: 5rem;
    cursor: pointer;
}
.business_profile_tables td{
    vertical-align: middle !important;
}




.edit_business .wizard-label{
    text-align: center;
    margin: 0 3.5rem;
}


.edit_business .MuiStep-root.MuiStep-horizontal.MuiStep-completed path{
    fill: #3699FF !important;
}
.edit_business .MuiStep-root.MuiStep-horizontal.MuiStep-completed .wizard-step{
    background-color: #fff;
}
.edit_business .MuiStep-root.MuiStep-horizontal.MuiStep-completed .wizard-title{
    color: #3699FF;
}

.edit_business .MuiStep-root.MuiStep-horizontal:first-child:not(.MuiStep-completed) path{
    fill: #3699FF !important;
}
.edit_business .MuiStep-root.MuiStep-horizontal.MuiStep-completed + .MuiStep-root.MuiStep-horizontal:not(.MuiStep-completed) path{
    fill: #3699FF !important;
}
.edit_business .MuiStep-root.MuiStep-horizontal:first-child:not(.MuiStep-completed) .wizard-step{
    background-color: #fff;
}
.edit_business .MuiStep-root.MuiStep-horizontal.MuiStep-completed + .MuiStep-root.MuiStep-horizontal:not(.MuiStep-completed) .wizard-step{
    background-color: #fff;
}
.edit_business .MuiStep-root.MuiStep-horizontal:first-child:not(.MuiStep-completed) .wizard-title{
    color: #3699FF;
}
.edit_business .MuiStep-root.MuiStep-horizontal.MuiStep-completed + .MuiStep-root.MuiStep-horizontal:not(.MuiStep-completed) .wizard-title{
    color: #3699FF;
}
.wizard-step-active{
    background-color: #ffffff !important;
}